const SIGNUP_URL = "/start";
const VENDOR_SIGNUP_URL = "/pros/join";

const text = {
  partner_network: {
    how_it_works: {
      title: "How It Works",
      subtitle:
        "You just tell us what to do. Then we’ll make a plan, connect you with your expert team, and get it done. It’s that simple!",
      steps: [
        {
          title: "Get access to our app",
          description:
            "Once you join Humming Homes as a member, you’ll get access to our app to start setting up your home profile.",
          image: "/partner-network/how-it-works/step-1.png"
        },
        {
          title: "Schedule your home service visit",
          description:
            "We’ll help you schedule your in-person home service visit, based on your availability",
          image: "/partner-network/how-it-works/step-2.png",
          callout: "Your report starts your maintenance journey!"
        },
        {
          title: "Get your projects and plan",
          description:
            "We’ll customize your maintenance plan, and stay ready for any new home needs you may have.",
          image: "/partner-network/how-it-works/step-3.png"
        }
      ]
    },
    we_provide: {
      services: [
        {
          pre_title: "Services we provide",
          title: "Renovations",
          description:
            "Your next remodeling project will feel like a breeze with the best vendors, and the right order of operations with home services projects. We handle:",
          includes: [
            "Flooring",
            "Basement Remodeling ",
            "Kitchen Remodeling",
            "Bathroom Remodeling",
            "And more!"
          ],
          cta: { reads: "Learn More", to: "/services/home-improvement" },
          image: "/partner-network/we-provide/renovations.png"
        },
        {
          pre_title: "Services we provide",
          title: "Landscaping & Lawn Care",
          description:
            "If there’s something that breaks, we can diagnose the problem, deploy a vendor solution. We handle things like: ",
          includes: [
            "Arborist Maintenance",
            "Lawn Care Service & Repair",
            "Irrigation",
            "Lawn Drainage",
            "And more!"
          ],
          image: "/partner-network/we-provide/landscaping.png",
          cta: { reads: "Learn More", to: "/services/landscaping" }
        },
        {
          pre_title: "Services we provide",
          title: "Pool Maintenance",
          description:
            "We’ll help install or remove any core appliances that you need to transform your home into your dream home and handle all seasonal maintenance for you - with a curated, recommended list right from the app.",
          includes: [
            "Heating & Pumps ",
            "Winterization ",
            "Covers, Linings & Finishes ",
            "And more!"
          ],
          cta: { reads: "Learn More", to: "/services/pool-maintenance" },
          image: "/partner-network/we-provide/pool-maintenance.png"
        }
      ]
    },
    heading: {
      pre_title: "Our PARTNER NETWORK",
      title: "Curated Network of Vetted Service Pros",
      description:
        "We work with the best vendors. For projects we don’t actively manage, we source quality vendors, and introduce them to you right away. This way, you can get started on your next project with the right vendor in-place, without any hold-ups.",
      cta: { reads: "join now", to: "/start" },
      image: "/partner-network/heading/mandala.png"
    }
  },
  faq: {
    title: "FAQ",
    description:
      "Have a question about our home management service? See answers to our most frequently asked questions below!",
    redirects: [
      { reads: "Membership", to: "#membership" },
      { reads: "Our Services", to: "#services" },
      { reads: "Partner Network", to: "#partner-network" },
      { reads: "Vendors", to: "#vendor" }
    ],
    categories: [
      {
        title: "General",
        questions: [
          {
            question: "What is Humming Homes?",
            answer:
              "Humming Homes is a home membership service that brings together easy-to-use technology and experienced people to help you have more good days at home."
          },
          {
            question: "What areas does Humming Homes currently operate in?",
            answer:
              "Currently, Humming Homes operates in the Hamptons, Westchester County (NY), Fairfield County (CT), Miami (FL) and Palm Beach (FL). Be on the lookout for more markets soon!"
          }
        ]
      },
      {
        id: "support",
        title: "Support",
        questions: [
          {
            question: "How do I reach you if I need something?",
            answer:
              "We know that questions and issues can come up when you least expect them, which is why we built a chat feature right into the Humming Homes app. You can reach your Humming Homes team at any time using your app chat. We respond in 2 hours for any general or project related inquiries. For emergencies, call our emergency phone line for immediate assistance. We respond immediately with all emergency requests, and will have your emergency triaged within 30 minutes."
          },
          {
            question: "What happens if I’m dissatisfied with the service?",
            answer:
              "Humming Homes offers a risk-free guarantee in which homeowners can to opt out of the agreement with 60 days' notice."
          },
          {
            question: "Will you be in-person at my home?",
            answer:
              "Our local expert will come on-site and in-person at your home. For individual projects, we will send licensed, pre-vetted vendors to your home to complete projects in a timely manner."
          }
        ]
      },
      {
        id: "membership",
        title: "Membership",
        questions: [
          {
            question: "What’s included in my membership plan?",
            answer:
              "Starting at $199 / year, you get access to our 24/7 Emergency Line, access to our team for expertise-led managed projects, vetted vendor introductions, and access to our app (think: organization and easy communication)."
          },
          {
            question: "How much does a Humming Homes membership cost?",
            answer:
              "Humming Homes starts at $199 / year for basic membership. For $599 / year, you receive an annual onsite home inspection to better determine your home health."
          },
          {
            question: "How does the membership work?",
            answer:
              "It’s easy - you sign up, get started on our app, schedule your home inspection, and then get a series of recommendations for your home’s ongoing maintenance. If you have any current projects, we’ll help with that too!"
          },
          {
            question: "Can I extend or renew my membership?",
            answer:
              "Yes - our memberships are annual memberships. You can renew your membership annually, and we’ll send you reminders throughout the year!"
          },
          {
            question: "How do I cancel my membership?",
            answer:
              "You can email us at memberships@humminghomes.com. We’ll take care of your cancellation for you! We ask for a 60-day opt-out."
          },
          {
            question:
              "What perks and benefits do I receive with my membership? ",
            answer:
              "Humming Homes has (a ton) of partner companies that we work with - you’ll have exclusive access to from companies like Sollis Health, ADT, and Douglas Ellimann."
          },
          {
            question: "Where does Humming Homes currently operate?",
            answer:
              "Humming Homes operates in Westchester County (NY), Fairfield County (CT), the Hamptons (NY), Miami (FL) and Palm Beach County (FL)."
          }
        ]
      },
      {
        id: "services",
        title: "Services",
        questions: [
          {
            question: "Do all these services come with my membership? ",
            answer:
              "You can request any of the following services from our team, and depending on the project - we’ll support you one of two ways. We’ll either support you with managing the project ourselves, or we’ll coordinate a vetted vendor introduction within 48 hours."
          },
          {
            question:
              "What is the difference between Managed Services and Partner Network? ",
            answer:
              "Managed services are projects that we manage end-to-end - from managing scope, sourcing vendors, negotiating prices, scheduling appointments, and ensuring the work is done from start to finish. Our Partner Network is for when you don’t need a long-term contract and don’t see the need for the project management - we’ll introduce you to our vetted network of hundreds of qualified service providers."
          },
          {
            question: "What vendors do I have access to? ",
            answer:
              "You have access to all Humming Homes vendors. For projects that we manage, we’ll be able to support in your project from start to finish. For all partner network projects, we’ll help introduce you to 2-3 vendors to help you get started. "
          },
          {
            question:
              "How do I reach out about my emergencies and how does it work? ",
            answer:
              "If you have a maintenance emergency, you’ll reach out to your home advising team first and let them know what your issue is. We’ll help to diagnose, recommend a solution, and deploy a service provider quickly where needed."
          },
          {
            question: "How will you manage my projects?",
            answer:
              "For all managed services, we’ll do the work for you - find a vendor, deploy a vendor and help you get started and manage your project for the beginning. You’ll be able to track your project progress and find all details related to your project within our app."
          },
          {
            question: "What is my personalized home maintenance plan? ",
            answer:
              "After you sign up for a Humming Homes subscription, we’ll come on-site and inspect your home. We’ll deliver a home maintenance plan in-app, customized for your unique home."
          },
          {
            question: "Why is there a service fee with my project? ",
            answer:
              "The service fee up to 15% helps us operate Humming Homes - this will be added to any projects you want us to manage, end-to-end."
          },
          {
            question: "Who is in charge of managing my projects? ",
            answer:
              "When you sign up, you’ll be assigned to your Humming Homes home advising team. This will be your go-to contact when it comes to managing your project from start to finish."
          }
        ]
      },
      {
        id: "partner-network",
        title: "Partner Network",
        questions: [
          {
            question: "What is the Humming Homes partner network?",
            answer:
              "The Humming Homes partner network is our extensive network of curated vetted service providers."
          },
          {
            question:
              "What does it mean to have access to the partner network? ",
            answer:
              "You’ll have access to any services within our partner network - we’ll hand off warm introductions to vetted vendors, so you’re able to get started on your next project."
          },
          {
            question: "What services are included in the partner network? ",
            answer:
              "We handle (almost) everything with the support of our partner network. For a full list of services, click here to learn more."
          },
          {
            question: "What is the cost to accessing your partner network?",
            answer:
              "Access to our partner network is included within your annual Humming Homes membership!"
          },
          {
            question: "Who should I reach out to about a project? ",
            answer:
              "You can reach out to your Humming Homes home advising team to help you get started on a project within our partner network."
          }
        ]
      },
      {
        id: "vendor",
        title: "Vendor",
        questions: [
          {
            question:
              "What does it mean to be a vendor in the Humming Homes community?",
            answer:
              "Being a vendor in the Humming Homes community comes with (a ton) of perks. We offer easy-access invoice payment so you don't have to chase payments down, you are connected to a steady stream of qualified leads, and we'll support you throughout the process."
          },
          {
            question: "Do I have to pay for leads?",
            answer:
              "No, that's the beauty of working with Humming Homes - we connect you to qualified leads, and you do what you do best — great service work."
          },
          {
            question: "How will Humming Homes help me get more projects?",
            answer:
              "As a service provider in the Humming Homes community, you’ll have access to our extensive network of homeowners and markets. We’re currently managing around 500+ homes and have 5 markets across the country."
          },
          {
            question: "How and when will I receive payment?",
            answer:
              "You’ll receive your digital payment immediately into your account once the project is closed out."
          },
          {
            question: "Who is my point of contact if I have issues?",
            answer:
              "Your Humming Homes Vendor Success Manager is your primary point of contact - they’ll support you in any billing and questions you may have as a service provider in the Humming Homes community."
          },
          {
            question:
              "Are there any requirements to become a Humming Homes vendor?",
            answer:
              "To become a Humming Homes vendor, we require all service providers to have proof of business, insurance, and photos of prior work. We additionally evaluate performance ongoing, based on homeowner feedback."
          }
        ]
      }
    ]
  },
  onboarding: {
    get_started: {
      title: "Start your Humming Homes membership today",
      includes: [
        "24/7 support: never worry about home emergencies again",
        "Meticulous in-person home inspection",
        "Personalized Home Health Report",
        "Access to our network of trusted service providers"
      ]
    },
    payment_confirmation: {
      success: {
        title: "Welcome to Humming Homes",
        description:
          "You’re now a part of the Humming Homes community! We’ve\n\nsent over a confirmation email with next steps on how to get\n\nstarted.",
        actions: [
          {
            reads: "Take Me Home",
            to: "/"
          }
        ],
        redirects: [
          { reads: "FAQs", to: "/faq" },
          { reads: "Services", to: "/services" },
          { reads: "How It Works", to: "/membership/how-it-works" }
        ]
      },
      failure: {
        title: "Sorry!",
        description:
          "We're unable to complete your transaction.\n\nPlease check your information or make sure your payment is up to date.",
        actions: [
          {
            reads: "Back",
            to: "/"
          }
        ],
        redirects: [
          { reads: "FAQs", to: "/faq" },
          { reads: "Services", to: "/services" },
          { reads: "How It Works", to: "/membership/how-it-works" }
        ]
      }
    },
    subscriptions: {
      BASIC: [
        {
          id: process.env.NEXT_PUBLIC_SUB_BASIC_PRICE_YEAR,
          title: "Basic Membership",
          price: "$199",
          original_price: "$300",
          description: "per year",
          subtitle: "Save 33%!",
          eyebrow: "Best value"
        },
        {
          id: process.env.NEXT_PUBLIC_SUB_BASIC_PRICE_MONTH,
          title: "Basic Membership",
          price: "$25",
          original_price: null,
          description: "per month",
          subtitle: "($300/year)",
          eyebrow: null
        }
      ],
      PRO: [
        {
          id: process.env.NEXT_PUBLIC_SUB_PRO_PRICE_MONTH,
          title: "Pro Membership",
          price: "$49",
          original_price: null,
          description: "per month",
          subtitle: null,
          eyebrow: null
        },
        {
          id: process.env.NEXT_PUBLIC_SUB_PRO_PRICE_YEAR,
          title: "Pro Membership",
          price: "$599",
          original_price: null,
          description: "per year",
          subtitle: null,
          eyebrow: null
        }
      ],
      DISCOUNT: [
        {
          id: process.env.NEXT_PUBLIC_SUB_BASIC_PRICE_MONTH,
          title: "Basic Membership",
          price: "$25",
          original_price: null,
          description: "per month",
          subtitle: null,
          eyebrow: null
        },
        {
          id: process.env.NEXT_PUBLIC_SUB_DISCOUNT_PRICE_YEAR,
          title: "Basic Membership",
          price: "$49",
          original_price: null,
          description: "per year",
          subtitle: null,
          eyebrow: null
        }
      ]
    }
  },
  vendors: {
    the_humming_homes_difference: {
      title: "The Humming Homes Difference",
      image: "/vendors/the_humming_homes_difference/HH-Difference.png",
      items: [
        {
          title: "No Hidden Fees",
          description:
            "There's no charge to join, no booking fees, no revenue sharing. Our priority is quality and on-time work for our Humming Home members. We give you jobs in exchange for reliable work."
        },
        {
          title: "Confirmed Jobs",
          description:
            "Our customers will instantly request a booking with you based on your completed profile. We also limit competition for jobs. Meaning no sales, just jobs."
        },
        {
          title: "Better Quality and Communication",
          description:
            "We'll help get estimates over the line and assist with communication with our Members. We'll ensure there are realistic expectations around budget or timeline so you can focus on the bigger task at hand - like cashing in on a successful deck renocation."
        },
        {
          title: "Flexibility and Control",
          description:
            "Whether you're just looking to pick up a few extra gutter cleanings here and there or looking to grow your business - you decide what types of jobs you'll take - when and where."
        }
      ]
    },
    how_it_works: {
      title: "How it works",
      steps: [
        {
          title: "Apply Today",
          description:
            "Apply today and get started with no hidden fees or payments. Compile your profile and a member of our team will review your application and be in touch within 48 hours.",
          image: "/vendors/how_it_works/sign-up.png",
          action: {
            reads: "become a vendor",
            to: "/pros/join"
          }
        },
        {
          title: "Get your first Job Request",
          description:
            "Once your profile is set up, our Members will have the ability to contact you directly via your preferred contact method to set up service calls or estimates. No need to maintain updated availability in an app or calendar - accept or reschedule each job as they come.",
          image: "/vendors/how_it_works/new-projects-1.png",
          snippet: "/vendors/how_it_works/new-projects-2.png"
        },
        {
          title: "Complete your first job",
          description:
            "You do what you do best and we're there every step of the way - ensuring a great experience for both you and our Members. We don't serve as the middle man when it comes to payments, but we'll ensure on-time payment directly from our members to you!",
          image: "/vendors/how_it_works/get-paid.png",
          snippet: "/vendors/how_it_works/get-paid-2.png"
        }
      ]
    },
    the_promise: {
      title: "The Vendor Promise",
      description:
        "Our goal in the Humming Homes community is to create a supporting environment for both our service providers and homeowners. As a service provider within our community, it’s important to keep in mind our standards and expectations.",
      includes: [
        {
          title: "Quality",
          description:
            "You’re providing the highest quality of work when it comes to projects – quality of work is just as important for you as it is for us."
        },
        {
          title: "Transparency and trust",
          description:
            "You’re communicating honestly about your expectations and limitations in your work and prices."
        },
        {
          title: "Reliability",
          description:
            "You’re respectful of your relationship with both Humming Homes and our homeowners."
        },
        {
          title: "Acceptance",
          description:
            "You’re treating every homeowner equally, regardless of their sexual orientation, gender, identity, race, ethnicity, or religion."
        },
        {
          title: "Collaboration",
          description:
            "The best way for us to improve and create the best possible experience for both our homeowners and our vendors is collaboration between us and you."
        }
      ]
    },
    looking_for_vendors: {
      title: "Looking for vendors to serve in",
      locations: [
        "/vendors/looking_for_vendors/westchester-county.png",
        "/vendors/looking_for_vendors/miami.png",
        "/vendors/looking_for_vendors/hamptons.png",
        "/vendors/looking_for_vendors/palm-beach.png",
        "/vendors/looking_for_vendors/fairfield-county.png"
      ]
    },
    heading: {
      title: "Discover a new way\n\nto connect with new\n\nhomeowners",
      description:
        "Connect with the right homeowners with immediate approvals, and\n\na guarantee of consistent, steady jobs.",
      cta: {
        reads: "Become a vendor",
        to: "/pros/join"
      },
      image: "/vendors/heading/mandala.png"
    },
    network: {
      title: "Growing our vendor network",
      subtitle:
        "We’re on the lookout for experts in the industry that offer a variety of services in these categories.",
      offerings: [
        { name: "Interior & exterior painting", icon: "paintroller" },
        { name: "Renovations, big & small", icon: "drill" },
        {
          name: "Electrical work",
          icon: "electrical"
        },
        {
          name: "Plumbing",
          icon: "plumbing"
        },
        {
          name: "HVAC",
          icon: "hvac"
        },
        {
          name: "All-season lawn maintenance",
          icon: "leaves"
        },
        {
          name: "Handyman",
          icon: "hammer"
        },
        { name: "Roofing", icon: "home" }
      ]
    }
  },
  start: {
    services: [
      {
        label: "Renovations",
        value: "Renovations",
        icon: "/common/base-icons/drill.svg"
      },
      {
        label: "Housewatching",
        value: "Housewatching",
        icon: "/common/base-icons/home.svg"
      },
      {
        label: "Daily Maintenance",
        value: "Daily maintenance",
        icon: "/common/base-icons/hammer.svg"
      },
      {
        label: "Vendor Management",
        value: "Vendor management",
        icon: "/common/base-icons/van.svg"
      },
      {
        label: "Emergency Support",
        value: "Emergency Support",
        icon: "/common/base-icons/clipboard.svg"
      },
      {
        label: "Interior & Exterior Painting",
        value: "Interior & Exterior Painting",
        icon: "/common/base-icons/paintroller.svg"
      },
      {
        label: "Lawn Maintenance",
        value: "Lawn Maintenance",
        icon: "/common/base-icons/leaves.svg"
      },
      {
        label: "Pool Maintenance",
        value: "Pool Maintenance",
        icon: "/common/base-icons/pool-maintenance.svg"
      }
    ]
  },
  contact: {
    title: "Your home deserves more;\n\nnow’s the time",
    cta: { label: "Get Your Membership", to: "/start" },
    actions: [
      {
        icon: "call",
        label: "Call Us",
        href: "tel:18334990689",
        description: "+1 (833) 499 - 0689"
      },
      {
        icon: "email",
        label: "Email Us",
        href: "mailto:hello@humminghomes.com",
        description: "hello@humminghomes.com"
      }
    ]
  },
  vendor_contact: {
    title: "Join our partner network",
    cta: {
      label: "Become a service provider",
      to: "/pros/join"
    },
    actions: []
  },
  footer: {
    contact_email: "hello@humminghomes.com",
    copyright: "© COPYRIGHT 2021 HUMMING HOMES, INC",
    legal_redirects: [
      { to: "/terms", name: "Terms of service" },
      { to: "/privacy", name: "Privacy policy" },
      { to: "/our-privacy-policy", name: "Your CA privacy rights" }
    ],
    redirects: [
      {
        title: "Homeowners",
        options: [
          {
            name: "How It Works",
            to: "/homeowners"
          },
          {
            name: "Refer a Friend",
            to: "/refer"
          }
        ]
      },
      {
        title: "Service Pros",
        options: [
          {
            name: "Become a Service Pro",
            to: "/pros"
          },
          {
            name: "Service Pro FAQ",
            to: "/pros/faq"
          }
        ]
      },
      {
        title: "About",
        options: [
          {
            name: "About Us",
            to: "/about"
          },
          {
            name: "FAQ",
            to: "/faq"
          },
          {
            name: "Services Offered",
            to: "/services"
          },
          {
            name: "Blog",
            to: "/blog"
          }
        ]
      }
    ]
  },
  navbar: {
    options: [
      {
        name: "For Homeowners",
        to: "/homeowners"
      },
      {
        name: "For Service Pros",
        to: "/pros"
      },
      {
        name: "About",
        to: "/about"
      },
      {
        name: "Blog",
        to: "/blog"
      }
    ],
    cta: { to: SIGNUP_URL, reads: "GET STARTED" },
    vendorCta: { to: VENDOR_SIGNUP_URL, reads: "BECOME A VENDOR" }
  },
  services: {
    heading: {
      title: "Our Services",
      description:
        "From the tasks you’ve been putting off for months to the ones you’re just not sure where to start, we’ll help you check them off your to-do list with our dedicated services and expert team.",
      image: "/services/heading/mandala.png",
      cta: { reads: "Join Now", to: SIGNUP_URL },
      services: [
        {
          image: "/services/heading/Emergency-Support.png",
          title: "24/7 Emergency Support"
        },
        {
          image: "/services/heading/Maintenance.png",
          title: "Maintenance & Improvement Management"
        },
        {
          image: "/services/heading/Network.png",
          title: "Curated Network of Vetted Service Pros"
        }
      ]
    },
    network: {
      title: "Curated Network to Vetted Service Pros",
      description:
        "With your Humming Homes membership, we’ll introduce you to 2-3 of the best quality vendors in our extensive network for your projects.",
      cta: {
        to: "/partner-network",
        reads: "Learn More"
      },
      steps: [
        { description: "Submit a request for a vendor introduction" },
        {
          description:
            "Receive 2-3 recommendations with portfolios of work & price comparisons"
        },
        {
          description:
            "We will follow up with you within 7 days to determine if you had a chance to meet and procure a vendor we recommended"
        }
      ]
    },
    emergency_support: {
      title: "24/7 Emergency Support",
      description:
        "Emergencies happen – with our 24/7 emergency support, our expert team will help you handle and find a solution quickly with your urgent request.",
      includes: [
        "Appliances",
        "Sewage Smell",
        "Pest Control",
        "Gutters",
        "Irrigation Repair",
        "Septic Tank & Well",
        "Toilet, Sink and Pipe",
        "Mold",
        "Water Temperature",
        "HVAC",
        "Generators",
        "And more!"
      ],
      process: {
        title: "The Process",
        description:
          "We’ll help you from the time you recognize a problem to the time we help you find a solution.",
        steps: [
          {
            icon: "/common/base-icons/hh.svg",
            label: "Reach out to your home advising team"
          },
          {
            icon: "/common/base-icons/clipboard.svg",
            label: "Receive a diagnosis and recommended solution"
          },
          {
            icon: "/common/base-icons/van.svg",
            label: "Quick deployment of service providers"
          },
          {
            icon: "/common/base-icons/home.svg",
            label: "Get back to your life"
          }
        ]
      }
    },
    maintenance: {
      title: "Maintenance & Improvement Management",
      description:
        "Receive expertise-led project management for specific service categories for projects you don’t want to manage yourself.",
      services: [
        {
          title: "Repair Maintenance",
          image: "/services/maintenance/Repair-Maintenance.png",
          description:
            "If something breaks in your home, we’ll help to diagnose the problem, deploy a service provider, and manage your project until it’s repaired.",
          includes: [
            {
              title: "Appliances",
              covers: [
                "Washer & Dryer",
                "Exhaust Fan",
                "Grill",
                "Central Vacuum",
                "Dishwasher",
                "Oven & Stove"
              ]
            },
            {
              title: "Mechanical, Electrical & Repairs",
              covers: [
                "Generators",
                "Electrical Maintenance",
                "Garage Doors",
                "Lighting Fixtures & Outlets"
              ]
            }
          ]
        },
        {
          title: "Home Improvement",
          description:
            "Your next remodeling project will feel like a breeze with the best vendors, and the right order of operations, and project management - from start to finish.",
          image: "/services/maintenance/Home-Improvement.png",
          includes: [
            {
              title: "Handyman & Carpentry",
              covers: [
                "Cabinetry",
                "Door Maintenance",
                "General Handyman",
                "Hanging & Mounting",
                "Installation & Assembly",
                "Molding, Trim, Casing Repair",
                "Painting",
                "Sealing, Grouting, Caulking",
                "Shelving"
              ]
            },
            {
              title: "Outdoor Improvements",
              covers: [
                "Powerwashing",
                "Gates & Fences",
                "Outdoor Furniture",
                "Chimney & Fireplace"
              ]
            }
          ]
        },
        {
          title: "Seasonal Maintenance",
          description:
            "Make sure your home is getting the care that it deserves - based on your in-person service visit, we’ll create a seasonal maintenance plan for your home.",
          image: "/services/maintenance/Seasonal-Maintenance.png",
          includes: [
            {
              title: "HVAC Systems",
              covers: ["Boiler", "HVAC", "Smart Systems"]
            },
            {
              title: "Outdoor Maintenance",
              covers: ["Gutters", "Irrigation"]
            }
          ]
        }
      ],
      the_project: {
        title: "Tech-Fueled Project Management",
        description:
          "With a variety of categories, we’ll be able to help you manage your home with any new projects, in addition to your personalized home maintenance plan. __We add up to a 15% project management fee on all managed projects, depending on project complexity.__",
        steps: [
          "Establish timeline",
          "Evaluate budget",
          "Understand the process and order of operations",
          "Deploy a vendor",
          "Fix your issue!"
        ],
        image: "/services/maintenance/Tech-Fueled-Project-Management.png"
      }
    },
    common: {
      learn_more: {
        description: "Learn More from an Expert",
        cta: { reads: "Learn More", to: "/" }
      },
      membership: {
        steps: [
          {
            title: "Download the app",
            description:
              "Once you join Humming Homes as a member, you’ll get access to our app."
          },
          {
            title: "Chat with your home advising team",
            description:
              "When you’re ready for a specialty project, reach out to your home advising team. "
          },
          {
            title: "Get started on your specialty project",
            description:
              "We’ll introduce you to 2-3 vendors to help you get started on your specialty project."
          }
        ]
      }
    },
    landers: {
      "home-improvement": {
        heading: {
          title: "Renovations",
          description:
            "Our partner network can help you get\n\nthrough your lingering punchlist and\n\nturn your dream home into a reality",
          cta: {
            reads: "Join Now",
            to: "/start"
          },
          image: "/services/landers/home-improvements.png"
        },
        includes: {
          title: "Get matched with pros for:",
          services: [
            {
              title: "Remodeling & Renovation",
              description:
                "Whether it’s a bathroom or kitchen renovation you need, or complete remodeling advice — we have you covered!"
            },
            {
              title: "Siding",
              description:
                "We’ll send someone to address warping or rotting, check interior moisture, and determine an ROI for a full-on replacement for your specific needs."
            },
            {
              title: "Alternative Energy",
              description:
                "Going green has never been easier — our network handles and transform your home to fit your sustainable lifestyle."
            },
            {
              title: "Awnings & Canopies",
              desription:
                "We’ll support you in finding a new awning, carport or canopy build for your home. Perfect for sitting on your porch, or relaxing in your patio!"
            },
            {
              title: "Window Coverings",
              desription:
                "We'll help you find an expert and manage installations for motorized blinds and other window coverings."
            },
            {
              title: "Other",
              description:
                "Reach out to your home advising team if you have any other home improvement needs for your home."
            }
          ]
        }
      },
      landscaping: {
        heading: {
          title: "Landscaping\n\nMaintenance &\n\nImprovements",
          description:
            "We’ll take care of all of your lawn care\n\nneeds through our network of expert\n\nlandscapers and arborists.",
          cta: {
            reads: "Join Now",
            to: "/start"
          },
          image: "/services/landers/landscaping.png"
        },
        includes: {
          title: "Get matched with pros for:",
          services: [
            {
              title: "Lawn Care",
              description:
                "We get to know your soil (really). We’ll connect you with specialists who know the best grasses for your region, the best way to fight weeds, and more! "
            },
            {
              title: "Arborists",
              description:
                "Tree removal can be complex - we have the best arborists that we work with to make sure the preparation, assessment and removal is done smoothly."
            },
            {
              title: "Trimming & Upkeep",
              description:
                "Get your yard ready for the spring and summer seasons - cleaning flower beds, plant, care and more."
            },
            {
              title: "Seasonal Planting",
              description:
                "Whether you need cool-season or warm season crops planted, raised flower beds, or the like — we support with all of your garden-related needs. "
            },
            {
              title: "Other",
              description:
                "Reach out to your home advising team if you have any other specific lawn maintenance needs for your home. "
            }
          ]
        }
      },
      "pool-maintenance": {
        heading: {
          title: "Pool Maintenance\n\n& Improvements",
          description:
            "Your pool’s maintenance is just as\n\nimportant as your home’s – we can help\n\nyou handle things from maintenance to\n\nimprovements.",
          cta: {
            reads: "Join Now",
            to: "/start"
          },
          image: "/services/landers/pool-maintenance.png"
        },
        includes: {
          title: "Get matched with pros for:",
          services: [
            {
              title: "Pool Winterization",
              description:
                "Pool opening, closing and winterization are all projects we can support. We have been there! "
            },
            {
              title: "Pool Cleaning",
              description:
                "We’ll help you to find the best way to maintain your pool with regular cleanings, every season."
            },
            {
              title: "Pool Heating",
              description:
                "Heaters and pumps are finicky beasts - we know the teams to help keep your equipment humming for the biggest weekends of the year"
            },
            {
              title: "Other",
              description:
                "Reach out to your home advising team if you have any other specific pool maintenance needs for your home."
            }
          ]
        }
      },
      "appliance-repair": {
        heading: {
          title: "Appliances",
          description:
            "If your fridge is broken or if you’re looking\n\nto replace your stove, we’ll help you find\n\nthe best vendor from our network.",
          cta: {
            reads: "Join Now",
            to: "/start"
          },
          image: "/services/landers/appliances.png"
        },
        includes: {
          title: "Get matched with pros for:",
          services: [
            {
              title: "Fridge & Ice Maker",
              description:
                "Appliances break from time to time. We’ll help you coordinate a fridge and ice maker install + removal."
            },
            {
              title: "Stove",
              description:
                "Get an expert to handle new gas lines, stove installation, or removal."
            },
            {
              title: "Grill",
              description:
                "We’ll help you get ready for those family BBQs with a grill installations, repairs, and removal."
            },
            {
              title: "Dishwasher",
              description:
                "For the day your dishwasher is no longer working. We’ll help pair you with a vendor to help you remove your old one and install a new one."
            },
            {
              title: "Washer & Dryer",
              description:
                "We know all about clogged hoses, foreign objects, overheated motors and the like. We'll fix any washer or dryer problem you have!"
            },
            {
              title: "Exhaust Fan",
              description:
                "Avoid damage and prevent moisture in your home and make sure your exhaust fans are working."
            },
            {
              title: "Oven & Range",
              description:
                "Make sure your range and oven are up to date to keep up with you and your cooking experiences."
            },
            {
              title: "Central Vacuum",
              description:
                "Need new parts for your central vacuum? Or need a new set up installed? We can help you order parts or set up a new system."
            },
            {
              title: "Other",
              description:
                "Reach out to your home advising team if you have any other removals and installations needed in your home."
            }
          ]
        },
        steps: {
          steps: [
            {
              title: "Download the app",
              description:
                "Once you join Humming Homes as a member, you’ll get access to our app."
            },
            {
              title: "Chat with your home advising team",
              description:
                "When you’re ready for a specialty project, reach out to your home advising team. "
            },
            {
              title: "Get started on your specialty project",
              description:
                "We’ll introduce you to 2-3 vendors to help you get started on your specialty project."
            }
          ]
        }
      }
    }
  },
  homepage: {
    your_membership: {
      app_highlights: "/homepage/your_membership/app-highlights.png",
      title: "Start Humming now with your Digital Home Manager",
      membership: {
        name: "HUMMING HOMES MEMBERSHIP",
        price: 199,
        price_monthly: 25,
        includes: [
          "24/7 Home Emergency Support for urgent repairs, leaks, heating issues and more",
          "Instant booking for HVAC, Handyman Electrical, and Plumbing jobs",
          "Same-day or next-day availability with vetted service providers",
          "Unlimited expert advice for bids, cost estimates, renovation planning and more",
          "Personalized Maintenance Plans to track, remind & book service pros"
        ],
        cta: {
          reads: "Get Started",
          to: SIGNUP_URL
        }
      }
    },
    how_it_works: {
      title: "How It Works",
      subtitle:
        "Sign up and download the app, share some details about your home and start humming. Get 24/7 emergency support, instantly book pros, and manage your home in one place.",
      steps: [
        {
          image: "/homepage/how_it_works/step-1.jpg"
        },
        {
          image: "/homepage/how_it_works/step-2.jpg"
        },
        {
          image: "/homepage/how_it_works/step-3.jpg"
        }
      ]
    },
    where_we_are: {
      title: "The Humming Homes Community",
      cta: { to: "/", reads: "Learn More" },
      locations: [
        {
          title: "Connecticut",
          image: "/homepage/where_we_are/fairfield.jpg",
          cities: [
            "Greenwich",
            "Darien",
            "Westport",
            "New Canaan",
            "Stamford",
            "and more!"
          ]
        },
        {
          title: "New York",
          image: "/homepage/where_we_are/westchester.jpg",
          cities: [
            "Bronxville",
            "Scarsdale",
            "Mamaroneck",
            "Larchmont",
            "Rye",
            "and more!"
          ]
        },
        {
          title: "New Jersey",
          image: "/homepage/where_we_are/new-jersey.jpg",
          cities: [
            "Short Hills",
            "Livingston",
            "Summit",
            "Chatham",
            "Madison",
            "Alpine",
            "Englewood",
            "and more!"
          ]
        },
        {
          title: "Florida",
          image: "/homepage/where_we_are/miami.jpg",
          cities: [
            "Coral Gables",
            "Coconut Grove",
            "Miami Beach",
            "Pinecrest",
            "Biscayne Park",
            "West Palm Beach",
            "Jupiter",
            "Palm Beach Gardens",
            "Boca Raton",
            "Wellington",
            "and more!"
          ]
        }
      ]
    },
    user_reviews: {
      reviews: [
        {
          location: "Scarsdale, NY",
          reviewer: "JAY R.",
          content:
            "“Humming Homes is an awesome service for managing your home in Westchester County. The app is really easy to use, their vendors are top-notch, and they give really helpful price/quality comparisons on local service providers.”"
        },
        {
          location: "Miami Beach, FL",
          reviewer: "GRACIE N.",
          content:
            "“We love how organized the app is, for everything from communication to invoices. It’s really easy to track everything in one place, and the Humming Homes team is very responsive.”"
        },
        {
          location: "Coral Gables, FL",
          reviewer: "MARK M.",
          content:
            "“As first time homeowners in migrating to South Florida, we were concerned about finding the right service providers and being able to manage needs while away. The Humming Homes advisors have been professional, responsive and an expert on a range of services.”"
        },
        {
          location: "Greenwich, CT",
          reviewer: "LAYLA L.",
          content:
            "“Humming Homes has helped me, tremendously. They came in, assessed all of my homes’ needs and then began to assign vetted vendors and contractors to my home. And when Humming Homes tells me a vendor is coming, I know they are actually coming.”"
        }
      ]
    },
    our_services: {
      title: "Available 24/7",
      subtitle:
        "Send your home manager a message or request any time - recommendations, advice or any reminders.",
      network: {
        cta: "Explore more services",
        title: "POWERED BY OUR SERVICE PRO NETWORK",
        offerings: [
          { name: "Interior & exterior painting", icon: "paintroller" },
          { name: "Renovations, big & small", icon: "drill" },
          {
            name: "Electrical work",
            icon: "electrical"
          },
          {
            name: "Plumbing",
            icon: "plumbing"
          },
          {
            name: "HVAC",
            icon: "hvac"
          },
          {
            name: "All-season lawn maintenance",
            icon: "leaves"
          },
          {
            name: "General contractors",
            icon: "hammer"
          },
          { name: "Pool maintenance", icon: "pool-maintenance" }
        ]
      },
      services: [
        {
          icons: ["emergency", "water-faucet"],
          title: "24/7 Home Care",
          description: "Emergency preparedness starts here",
          includes: [
            "Response time within 3 hours for all emergencies",
            "Emergency diagnosis and solution",
            "Quick deployment of service provider",
            "Expert guidance"
          ]
        },
        {
          icons: ["clipboard", "home"],
          title: "Maintenance & Improvement Projects",
          description: "Expertise-led project management and support",
          includes: [
            "Project timelines and bids established",
            "Preferred pricing",
            "No scope creep or haggling",
            "Project updates in-app "
          ]
        },
        {
          icons: ["wrench", "screwdriver", "hammer"],
          title: "Top Tier Vendors & Preferred Pricing",
          description: "Our vendors make things to last",
          includes: [
            "Best in-class vendors",
            "Same-day and next day appointments",
            "No pricing haggling or project scope creep"
          ]
        }
      ]
    },
    we_are_growing: {
      title: "We’re Growing Fast",
      metrics: [
        {
          value: 500,
          title: "Homes Actively\n\nManaged",
          withPostfix: true,
          animated: true
        },
        {
          value: 5,
          title: "Markets Across\n\nthe Country",
          animated: false
        },
        {
          value: 10000,
          title: "Home Service\n\nProjects Completed",
          withPostfix: true,
          animated: true
        },
        {
          value: 2000,
          title: "In-Network\n\nVendors",
          withPostfix: true,
          animated: true
        }
      ]
    },
    one_membership: {
      title: `Your Digital Home Manager Has Arrived`,
      subtitle:
        "With Humming Homes, you get a digital home manager that handles home emergencies, books vetted service pros, provides expert advice and more.",
      membership_includes: [
        "❗ 24/7 Home Emergency Support for urgent repairs, leaks, loss of heating, cooling or hot water and power outages",
        "🔨 On-demand services for HVAC, Electrical, Plumbing and Handyman jobs",
        "🗓️ Same-day or next-day availability with vetted service providers",
        "📱 Unlimited expert advice for bids, cost estimates, wear & tear and renovation planning",
        "🏠 Personalized Maintenance Plans to track, remind & book service pros"
      ],
      main_services: [
        {
          category: "Expertise",
          title: "Annual Home Diagnostic",
          image: "/homepage/one_membership/expertise.png",
          description:
            "We’ll come on-site & evaluate your home! You will receive a tailored diagnostic report with a health score and recommended projects."
        },
        {
          category: "MANAGED SERVICES",
          title: "Maintenance & Improvement",
          image: "/homepage/one_membership/managed-services.png",
          description:
            "Fully automated project management, four digital seasonal maintenance plans, and a recommended list of ongoing projects, all within our proprietary app."
        },
        {
          category: "EMERGENCy care",
          title: "24/7 Home Care",
          image: "/homepage/one_membership/home-care.png",
          description:
            "Emergencies are a thing of the past, with 24/7/365 support. HVAC issues? No problem. Sump pump stopped working? We’ve got it handled."
        },
        {
          category: "service pros",
          title: "Preferred Pricing & Vendors",
          image: "/homepage/one_membership/preferred-pricing.png",
          description:
            "We equip you with the best of the best vendors, who will accommodate same and next-day requests, all for a reasonable cost."
        }
      ]
    },
    to_do_list: {
      title: "Handled for you, end-to-end",
      subtitle:
        "Emergencies and other urgent needs constantly come up, along with that ever growing list of to-do's for your home. Worry no longer - Humming Homes can help you take care of all of it.",
      reviews: [
        {
          pp: "/homepage/to_do_list/pp-1.png",
          looking_for_help_with: "Finding Service Pros for Urgent Repairs",
          comment:
            "I have a few that I work with for more recurring services, but quality and reliability is hard.",
          services: [
            { name: "Plumbers", icon: "plumbing" },
            { name: "Power Washers", icon: "home" },
            { name: "Chimney Cleaners", icon: "leaves" }
          ]
        },
        {
          pp: "/homepage/to_do_list/pp-2.png",
          looking_for_help_with: "Unexpected Home Emergencies",
          comment:
            "I feel like there's always something that comes up, big or small and I'm always looking for either a service pro that is impossible to book or guidance on how to solve it myself.",
          services: [
            { name: "Leaks", icon: "water-faucet" },
            { name: "Power Outages", icon: "electrical" },
            { name: "Loss of Heating or Cooling", icon: "hvac" }
          ]
        },
        {
          pp: "/homepage/to_do_list/pp-3.png",
          looking_for_help_with: "Home Maintenance & Upkeep",
          comment:
            "I know there’s stuff I need to do, but it’s so hard to keep track of and do it when I’m supposed to.",
          services: [
            { name: "Dryer Duct Cleaning", icon: "wrench" },
            { name: "Replace Air Filters", icon: "drill" },
            { name: "Annual Generator Check-up", icon: "clipboard" }
          ]
        }
      ]
    }
  },
  how_it_works: {
    our_app: {
      title:
        "Our app is your single point of all contact for all projects — big and small.",
      highlights: [
        {
          title: "Home information",
          description: "Upload and share  your home information to get started",
          screenshot: "/how-it-works/our_app/home-information.png",
          backgroundColor: "#5C8080",
          color: "#FFFFFF"
        },
        {
          title: "New Projects",
          description:
            "Create and submit new projects based on your home needs",
          screenshot: "/how-it-works/our_app/new-projects.png",
          backgroundColor: "#C3D5D5",
          color: "#000000"
        },
        {
          title: "Your Plan",
          description: "Find your personalized maintenance plan for your home",
          screenshot: "/how-it-works/our_app/your-plan.png",
          backgroundColor: "#465F5F",
          color: "#FFFFFF"
        },
        {
          title: "Project timeline",
          description:
            "Get a summary of all active projects from start to finish",
          screenshot: "/how-it-works/our_app/project-timeline.png",
          backgroundColor: "#5C8080",
          color: "#FFFFFF"
        },
        {
          title: "Attention",
          description:
            "Get regular alerts on projects that need your attention",
          screenshot: "/how-it-works/our_app/attention.png",
          backgroundColor: "#C3D5D5",
          color: "#000000"
        },
        {
          title: "Invoices",
          description:
            "Pay all your invoices in-app once your project is complete",
          screenshot: "/how-it-works/our_app/invoices.png",
          backgroundColor: "#465F5F",
          color: "#FFFFFF"
        },
        {
          title: "Chat Made Easy",
          description: "Chat with your Humming Homes Team anytime",
          screenshot: "/how-it-works/our_app/chat.png",
          backgroundColor: "#5C8080",
          color: "#FFFFFF"
        },
        {
          title: "Spend Report",
          description: "Look through your spend reports in one place",
          screenshot: "/how-it-works/our_app/report.png",
          backgroundColor: "#C3D5D5",
          color: "#000000"
        }
      ]
    },
    heading: {
      title: "Hand over your to-do list. We’ll take it from there.",
      subtitle:
        "Our service is simple: we pair you with real experts who take on your home maintenance projects, big and small — from beginning to end.",
      cta: {
        reads: "Join Now",
        to: SIGNUP_URL
      },
      asset: "/how-it-works/heading/mandala.png"
    },
    steps: {
      home_coordinator_pp: "/how-it-works/steps/home-coordinator.png",
      steps: [
        {
          title: "Get Access to Our App",
          description:
            "Once you join Humming Homes as a member, you’ll get access to our app to start setting up your home profile.",
          chat_bubble:
            "Welcome Home, John. You’re now a member of Humming Homes! To get started, make sure you set up your home profile.",
          image: "/how-it-works/steps/step-1.png"
        },
        {
          title: "Schedule Your Home Service Visit",
          description:
            "We’ll help you schedule two home service visits, based on your availability to help you curate a personalized home maintenance plan.",
          chat_bubble:
            "Now that you’re home profile is all good to go, let me know when the best time for you to set up a home inspection is. This will help us create your maintenance plan.",
          redirect: {
            reads: "Read a sample report!",
            file: "/user/HH-Sample_Plan.pdf"
          },
          learn_more: [
            {
              icon: "home",
              description: "Schedule your home service visit"
            },
            {
              icon: "water-faucet",
              description:
                "Get a certified walk-through with an expert, inspecting areas of concern and bolstering your in-app home profile."
            },
            {
              icon: "clipboard",
              description:
                "Receive your customized maintenance plan, specific for your home needs."
            }
          ],
          image: "/how-it-works/steps/step-2.png"
        },
        {
          title: "Get Your Projects and Plan",
          description:
            "We’ll customize your seasonal maintenance plan, send you vendor recommendations for specialty projects you’d like to complete, and stay ready for any new home needs you may have.",
          image: "/how-it-works/steps/step-3.png",
          chat_bubble:
            "Hey John! We see that you have a seasonal lawn clean up coming up – we found a vendor that’s available tomorrow for your project."
        },
        {
          title: "Get Back to Your Life",
          description:
            "With our mobile-first platform, you can now sit back and get back to everything else you’ve got going on. Chat with your home advising team, submit project requests, and pay invoices at your fingertips.",
          image: "/how-it-works/steps/step-4.png",
          chat_bubble:
            "Hey John – looks like you have an invoice you need to pay out. Let me know if I can explain anything or answer any questions."
        }
      ]
    }
  },
  our_membership: {
    exclusive_access: {
      title: "Exclusive Access to Member Perks",
      subtitle:
        "Get exclusive access and perks to our partners with your membership!",
      partners: [
        "/our-membership/exclusive-access/douglass-elliman.png",
        "/our-membership/exclusive-access/adt.png",
        "/our-membership/exclusive-access/sollis.png"
      ]
    },
    additional_benefits: {
      title: "Additional Benefits",
      benefits: [
        // {
        //   title: "Bill Pay & Communication",
        //   description:
        //     "Pay out all your invoices and create and track your projects within our app.",
        //   includes: [
        //     "Easy 1-click payment for vendors",
        //     "In-app invoices",
        //     "Summary of all monthly expenses, by category",
        //     "Chat with your home advising team"
        //   ]
        // },
        {
          title: "Annual Home Diagnostic & Home Health Report",
          description:
            "We’ll generate a personalized seasonal maintenance plan for your home’s needs – year round, summer, spring, fall, and winter!",
          includes: [
            "4 seasonal maintenance plans",
            "Annual in-person home diagnostic",
            "Home health score",
            "Discrete project planning to improve your home's score and value "
          ]
        }
      ]
    },
    heading: {
      title: "Your Home,\n\nManaged by Experts",
      description:
        "Don't let your home be a burden - get your personalized digital home manager to work for you so you can get back to enjoying time on the things you love.",
      pricing: "$199 / year",
      image: "/our-membership/heading/Header-Mandala.png",
      cta: {
        reads: "Join Now",
        to: SIGNUP_URL
      }
    },
    maintenance_plan: {
      heading: {
        title: "A customized home maintenance plan just for you",
        the_plan: {
          title: "Your personalized home maintenance plan ",
          includes: [
            "Annual home diagnostic",
            "Home Health Report",
            "Understanding of your home’s risk profile across critical areas",
            "Diagnostics across core appliances and structures (roof, foundation, etc.)",
            "And more!"
          ]
        },
        cta: {
          reads: "Sample Plan",
          file: "/user/HH-Sample_Plan.pdf"
        },
        image: "/our-membership/maintenance-plan/Our-Membership-Plan.png"
      },
      what_you_get: {
        title: "What You Get",
        services: [
          {
            title: "24/7 Home Care",
            description:
              "We'll handle and find a solution for your urgent requests with our 24/7 emergency support team",
            includes: [
              "Leaking Pipe",
              "Flooding",
              "Urgent HVAC issues",
              "Power outage"
            ]
          },
          {
            title: "Top Tier Vendors & Preferred Pricing",
            description:
              "We’ll help introduce you to vendors within our curated network to help you with your projects.",
            includes: [
              "Basement Remodeling",
              "Kitchen Remodeling",
              "Pool Maintenance",
              "Landscaping & Lawn Care"
            ]
          },
          {
            title: "Unlimited Repairs & Home Improvement Projects",
            description:
              "We’ll manage your projects, from start to finish, and deploy any service providers as needed.",
            includes: [
              "Project kick-off calls",
              "Sourcing bid estimates",
              "Deploying vendors",
              "In-app project tracking"
            ]
          }
        ]
      }
    }
  },
  benefits: {
    BASIC: [
      "❗ 24/7 support for home emergencies",
      "🔨 On-demand services for HVAC, Electrical, Plumbing and Handyman Jobs & Home Inspections",
      "🏠 Unlimited home maintenance expert advice"
    ],
    PRO: [
      "Comprehensive home inspection",
      "Dedicated team of home advisors",
      "🔨 On-demand services for HVAC, Electrical, Plumbing and Handyman Jobs & Home Inspections",
      "Maintenance plans tailored for your home",
      "24/7 support for home emergencies",
      "Unlimited on-demand expert advice"
    ],
    INSPECTION: [
      "Manage all maintenance & upkeep in our app to avoid long term issues",
      "Guaranteed next-day service appointments",
      "24/7 support for home emergencies",
      "On-demand expert advice, whenever you need"
    ]
  },
  urlDerivedIntroText: {
    handyman:
      "A Handyman & Home Maintenance Service that Gives You Back Time, Money and Peace\xa0of\xa0Mind"
  }
};

export default text;
